/* ==========================================================================
   Fonts
   ========================================================================== */

@font-face {
    font-family: 'UniversLTStd-LightObl';
    src: url("../fonts/UniversLTStd-LightObl.eot?#iefix") format("embedded-opentype"), url("../fonts/UniversLTStd-LightObl.otf") format("opentype"), url("../fonts/UniversLTStd-LightObl.woff") format("woff"), url("../fonts/UniversLTStd-LightObl.ttf") format("truetype"), url("../fonts/UniversLTStd-LightObl.svg#UniversLTStd-LightObl") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'UniversLTStd-BoldObl';
    src: url("../fonts/UniversLTStd-BoldObl_gdi.eot");
    src: url("../fonts/UniversLTStd-BoldObl_gdi.eot?#iefix") format("embedded-opentype"), url("../fonts/UniversLTStd-BoldObl_gdi.woff") format("woff"), url("../fonts/UniversLTStd-BoldObl_gdi.ttf") format("truetype"), url("../fonts/UniversLTStd-BoldObl_gdi.svg#UniversLTStd-BoldObl") format("svg");
    font-weight: 700;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-25CA;
}

@font-face {
    font-family: 'UniversLTStd-Bold';
    src: url("../fonts/UniversLTStd-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/UniversLTStd-Bold.otf") format("opentype"), url("../fonts/UniversLTStd-Bold.woff") format("woff"), url("../fonts/UniversLTStd-Bold.ttf") format("truetype"), url("../fonts/UniversLTStd-Bold.svg#UniversLTStd-Bold") format("svg");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'UniversLTStd-Light';
    src: url("../fonts/UniversLTStd-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/UniversLTStd-Light.otf") format("opentype"), url("../fonts/UniversLTStd-Light.woff") format("woff"), url("../fonts/UniversLTStd-Light.ttf") format("truetype"), url("../fonts/UniversLTStd-Light.svg#UniversLTStd-Light") format("svg");
    font-weight: normal;
    font-style: normal;
}


// new fonts - update

@font-face {
    font-family: 'MaisonNeue-Book';
    src: url("../fonts/MaisonNeue-Book.woff") format("woff"),  url("../fonts/MaisonNeue-Book.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MaisonNeue-Light';
    src: url("../fonts/MaisonNeue-Light.woff") format("woff"),  url("../fonts/MaisonNeue-Light.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'MaisonNeue-Medium';
    src: url("../fonts/MaisonNeue-Medium.woff") format("woff"),  url("../fonts/MaisonNeue-Medium.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
}