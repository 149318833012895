/* ==========================================================================
   Helper classes
   ========================================================================== */
.container {
    margin: 0 auto;
    position: relative;
    width: 1120px;
}

.container:before, .container:after {
    content: " ";
    display: table;
}

.container:after {
    clear: both;
}

.container {
    *zoom: 1;
}

.container-fluid {
    width: 100%;
    padding: 0 15px;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

/*
 * Extends the .visuallyhidden class to allow the element
 * to be focusable when navigated to via the keyboard:
 * https://www.drupal.org/node/897638
 */
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

img {
    max-width: 100%;
}

ul, li {
    list-style: none;
    padding: 0;
    margin: 0;
}

p {
    margin: 0;
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
    margin: 0;
    line-height: 1em;
    padding: 0;
}
body.support_update{
   h2{
            color: $black;
        font-size: 17px;
        line-height: 1.3em;
    } 
}


p {
    color: $black;
    font-size: 17px;
    line-height: 1.3em;
}

a {
    text-decoration: none;
    color: $black;
    
    &:hover{
      color: $link-colour; 
      font-family: inherit; 
    }

}

input[type="text"], input[type="email"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
}

/* ==========================================================================
   Responsive helpers
   ========================================================================== */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/*
 * Grid helpers inspired from Twitter Bootstrap
 */
.row {
    margin: 0 -15px;
}

.row:before, .row:after {
    content: "";
    display: table;
}

.row:after {
    clear: both;
}

.row {
    *zoom: 1;
}

.row > div {
    padding: 0 20px;
}

.row .prize-col {
    padding: 0 10px;
}

.row8 {
    margin: 0 -4px;
}

.row8 > div {
    padding: 0 4px;
}

.col15 {
    width: 20%;
    float: left;
}

.col12 {
    width: 100%;
    float: left;
}

.col11 {
    width: 91.666666666667%;
    float: left;
}

.col10 {
    width: 83.333333333333%;
    float: left;
}

.col9 {
    width: 75%;
    float: left;
}

.col8 {
    width: 66.666666666667%;
    float: left;
}

.col7 {
    width: 58.333333333333%;
    float: left;
}

.col6 {
    width: 50%;
    float: left;
}

.col5 {
    width: 41.666666666667%;
    float: left;
}

.col4 {
    width: 33.333333333333%;
    float: left;
}

.col3 {
    width: 25%;
    float: left;
}

.col2 {
    width: 16.666666666667%;
    float: left;
}

.col1 {
    width: 8.3333333333333%;
    float: left;
}

.hidden-desktop {
    display: none;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.table {
    display: table;
    width: 100%;
    height: 100%;
}

.table-cell {
    display: table-cell;
    width: 100%;
    vertical-align: middle;
}

.spacer {
    width: 100%;
    padding-top: 7.5%;
}

.spacer:before,
.spacer:after {
    content: " ";
    display: table;
}

.spacer:after {
    clear: both;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.mdbottom35 {
    margin-bottom: 35px;
}

strong {
    font-weight: normal;
}

/* 5 column grid helper */

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

/* ==========================================================================
   Browser Upgrade Prompt
   ========================================================================== */

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: $black;
    padding: 0.2em 0;
}

/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html {
    min-height: 100%;
    position: relative;
}

body {
    font-family: $light_font_family; ;
    //font-size: 17px;
    color: $black;
    padding-top: 58px;
    min-height: 100%;
    //font-size: 16px;
    font-size: $font-size;
    line-height: 1.3;
    //background: #F4F2EF;
}

body{
    padding-top: $header-without-submenu;

    &.with-submenu {
    // padding-top: 191px;
    //padding-top: 164px;
    // padding-top: 132px;
        padding-top: $header-with-submenu;
    }

    &.with-submenu-small {
    // padding-top: 141px;
       padding-top: $header-with-submenu-small;
    }

    &.with-submenu-small,  &.with-submenu {
        @media only screen and (max-width: $mobile-breakpoint) {
             padding-top: $header-without-submenu;
        }
    }
}

/* ==========================================================================
   Header
   ========================================================================== */
.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: $white;
    z-index: 200;
}

// .header .logo {
//     float: left;
//     margin: 15px 0;
//     width: 110px;
//     height: 60px;
// }

// .header .logo img {
//     display: block;
// }

.header .menu {
    float: right;
    margin-top: 34px;
}

.header .menu{
    
    .menu-col

    li {
        float: left;
    }

    @media only screen and (max-width: $mobile-breakpoint){
        .menu-col{
            width: 50%;
            float: left;
            padding-right: 10px;
        }

        li{
            float: none;
        }

    }

}

.header .menu li + li {
    margin-left: 60px;
}

.header .menu li a {
    display: block;
}

.header .menu li.active > a {
    font-family: $light_font_family; 
}


// .header .sub-menu ul li a {
//     font-size: 17px;
//     line-height: 1.2;
//     padding: 4px 2px 0px;
//     display: block;
// }


.header .sub-menu ul li.active a {
    font-family: $light_font_family; 
}

// .header .sub-menu-level2 {
//     background: #F4F2EF;
//     text-align: center;
//     padding: 13px 0;
// }

// .header .sub-menu-level2 ul {}

// .header .sub-menu-level2 ul li {
//     display: inline-block;
//     margin: 0 25px;
// }

// .header .sub-menu-level2 ul li a {
//     font-size: 17px;
//     line-height: 1.2;
//     padding: 4px 2px 0px;
//     display: block;
// }

// .header .sub-menu-level2 ul li.active a {
//     font-family: $bold_font_family; 
// }

// .header .sub-menu-level2.no-sub {
//     display: none;
// }

.header .color-blue {
    background: #7BAFDE;
}

.header .color-light-blue {
    background: #B0CEE9;
}

.menu-icon {
    float: right;
    display: none;
    //margin-top: 31px;
    margin-top: 25px;
    margin-left: 0;
    width: 24px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.menu-icon:before, .menu-icon:after,
.menu-icon .menu-icon__text {
    background: $black;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 4px 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 100%;
}

.menu-icon .menu-icon__text {
    text-indent: -9999px;
}



.subscribe-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    display: none;
}

.subscribe-popup {
    position: fixed;
    top: 0;
   // left: 0;
   // right: 0;
    bottom: 0;
    margin: auto;
    //width: 540px;
    width: 580px;
    height: 440px;
    background: #1AB790;
    z-index: 99999;
    text-align: center;
    display: none;
    border-radius: 5px;
    -webkit-font-smoothing: antialiased;
    //border: 1px solid #009D7A;
}

.subscribe-popup.dev {
    left: 50%;
    //right: 0;
   // margin: 0 auto;
    top: 50%;
    transform: translate(-50%,-50%);
    //height: auto;
    background: $light-grey;
    z-index: 99999;
    text-align: center;
    display: none;
    border-radius: 5px;
    //border: 1px solid #009D7A;
    box-shadow: $box-shaddow-btn;

    h2 {
        text-align: left;
        color: $black;
        font-family: $light_font_family; 
        font-size: 24px;
        line-height: 1.2;
        margin: 40px 0;
    }

    .input{
        margin-bottom: 20px;
    }

    .input[type="text"] {
        display: block;
        width: 100%;
        height: 30px;
        font-family: $light_font_family; ;
        font-size: 15px;
        color: $black;
        border: none;
        padding: 2px 10px;
        //margin-bottom: 8px;
    }

    .input[type="email"] {
        display: block;
        width: 100%;
        height: 30px;
        font-family: $light_font_family; ;
        font-size: 15px;
        color: $black;
        border: none;
        padding: 2px 10px;
        margin-bottom: 35px;
        line-height: 30px;
    }

    .input:placeholder-shown {
        color: $black;
    }
    input[type=checkbox].custom-checkbox+label{
        color: $black;
        text-align: left;
    }
    
    
    .btn {
        // background: $white;
        // min-width: inherit;
        //padding: 8px 10px 2px;
       // margin: 0 4px 10px;
       min-width: 120px;
       color: $link-colour;
    }

    .btn-cancel {
        // display: inline-block;
        // border: 0;
        // padding: 8px 10px 2px;
        // font-family: $bold_font_family; 
        // color: $white;
        // background: #009D7A;
        // min-width: inherit;
        // margin: 0 4px 10px;
    }

    .input.error {
        border: 1px solid red !important;
    }
}

.subscribe-popup.dev form {
    margin-bottom: 40px;
}

.popup-container {
    width: 100%;
    //max-width: 280px;
    margin: 0 auto;
    //padding: 0 20px;
    padding: 0 20px;
}

.dev .popup-container {

    .container{
        width: inherit;
    } 

    width: 100%;
    // max-width: 280px;
    //max-width: 480px;
    margin: 0 auto;
    // padding: 0 20px;
    padding: 0 40px;
   

    @media only screen and (max-width: $mobile-breakpoint) {
        padding: 0 0px;
        
        .container{
            display: block;
        } 

        .row{
            & > div{
                padding: 0 15px;
            }
        }

        label{
            color: $black;
        }
    }

}





.popup-open {
    height: 100vh;
    overflow: hidden;
}

.popup-open .footer {
    position: relative;
}

/* ==========================================================================
   Main
   ========================================================================== */
//.main{ background: #F4F2EF; }
.main {
    background: $white;
}

.slideshow-section {
    //margin-bottom: 120px;
   
    position: relative;
    height: auto;
}

.slideshow-section .cycle-slideshow {
    height: 100%;
}

.slideshow-section.home {
    height: auto;
}

.slideshow-section .slide {
    width: 100%;
    position: relative;
    height: auto;
    // background: $white;
}

.slideshow-section .slide img {
    display: block;
    margin: 0 auto;
    max-height: 100%;
    width: auto;

    @media only screen and (max-width: $mobile-breakpoint) {
         width: 100%;
         height: auto; 
    }
}

.slideshow-section  {
    .caption{
        position: absolute;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
       // text-align: center;
        color: $white;
        
        //text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);

        p{
            display: block;
             clear: both;
            img{
                display: block;
                clear: both;
            }
            & + h1, & + h2{
                padding-top: 30px;
            }
        }

        h1,h2{
            display: block;
            clear: both;
            font-size: 70px;
            font-family: $light_font_family;
            
            @media only screen and (max-width: $mobile-breakpoint) {
                font-size: 40px;
                font-family: $light_font_family;  
            }

        }
        h1 + h2{
             padding-top: 30px;
        }

        &--logo{
            h2{
                font-family: $light_font_family;  
            }
        }
    }

}

.slideshow-section .caption .container {
    height: 100%;
}

.slideshow-section .pager {
    text-align: center;
    height: 8px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -25px;
}

.slideshow-section .pager span {
    display: inline-block;
    font-size: 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 5px;
    background: #CCCCCB;
    vertical-align: top;
}

.slideshow-section .pager span.cycle-pager-active {
    background: #808080;
}

.section-block {

    &.top{
        padding-bottom: 30px;
    }
    &--shop{
        padding-bottom: 20px;
    }
    &--map{
        //padding-bottom: 50px;
        padding-bottom: 100px;
    }

    &--grey-background{
       background-color: $light-grey;
    }
    &--inline-banner{
        padding: 100px 0;

        .adv{
            .text{
                padding-top: 0;
                padding-left: 30px;
                
                @media only screen and (max-width: $mobile-breakpoint) {
                    padding-top: 50px;
                }

                h3{
                    font-size: 37px;

                }
                span{
                    display: block;
                    font-family: $light_font_family;
                    font-size: 17px;
                    &:hover{
                        color: $link-colour;
                    }
                }
            }
        }
    }
    &--sponsors{
         padding: 75px 0;
    }
}

// gallery channel styling

body.single-learn-artists-work, body.single-learn-childrens-gallery{
    .slideshow-section {
        margin-bottom: 0px;
        padding-bottom: 80px;
        position: relative;
        height: auto;
        .container{
            overflow: hidden
        }
        img{
            max-height: none;
            width: 100%;
            height: auto;
        }
    
        .video{
            &Wrapper{
            height: 100%;
            }
            &-overlay{
                position: absolute;
            }
        }
    }
}


.header-block {
    //background: #9AE8DE;
    //color: $white;
    color: $black;
    position: relative;
     margin-top: 40px;
    padding: 0px 4px 8px;
    margin-bottom: 34px;
}

.header-block.yellow {
    background: #FFD649;
}

.header-block.pink {
    background: #F177AE;
}

.header-block.green {
    background: #00AF84;
}

.header-block h2 {
    font-size: 39px;
    font-family: $light_font_family; 
    margin-bottom: -12px;
}

.header-block .date {
    position: absolute;
    right: 0;
    bottom: -1px;
    //font-size: 20px;
    font-size: 17px;
    font-family: $light_font_family; 
    //color: $white;
    padding-right: 5px;
}

.content-block {
  //  margin-bottom: 110px;
}

.article-block {
    margin-bottom: 30px;
}

.article-block .media {}

.article-block .media img {
    display: block;
    width: 100%;
    height: auto;
}

.article-block .text {
    background: $white;
   // padding: 15px 8px;
    padding: 15px 0;
    position: relative;
}

// .article-block .text:after {
//     content: "";
//     width: 0;
//     height: 0;
//     border-style: solid;
//     border-color: transparent transparent #A2D9D6 transparent;
//     border-width: 0 0 20px 20px;
//     position: absolute;
//     bottom: 0;
//     right: 0;
// }

.article-block .text.without-tr:after {
    content: "";
    display: none;
}

.article-block .text h3 {
    font-size: 24px;
    font-family: inherit; 
    margin-bottom: 8px;
    line-height: 1.2;
    transition: font-family .2s;
}

.article-block .text.title-only h3 {
    margin-bottom: 0;
}

.article-block .text .date {
    font-size: 17px;
   // padding-top: 12px;
}

.article-block .text h3 em {
    //font-family: $bold_oblique_font_family;
    font-family: $light_font_family; 
    font-style: italic;
}

.article-block .text p {
    font-size: 16px;
}

.article-block .blue-row {
    background: #7BAFDE;
    padding: 15px 8px;
    font-size: 18px;
}

.article-block .blue-row .price {
    font-family: $bold_font_family; 
}

.loaded-items {
    display: none;
}

.collection-item {
    display: block;
    margin-bottom: 30px;
}

.collection-item .caption {
    display: block;
    margin-bottom: 30px;
    color: $white;
    background: #666666;
    padding: 10px;
    position: absolute;
    display: none;
}

.btn-show {
    text-align: center;
}

.btn-show a {
    display: inline-block;
    font-family: $bold_font_family; 
    color: $white;
    background: #666666;
    width: 210px;
    padding: 14px 0px 6px;
    text-align: center;
}

.btn-grey {
    display: inline-block;
    font-family: $bold_font_family; 
    color: $white;
    background: #666666;
    width: 210px;
    padding: 14px 0px 6px;
    text-align: center;
}


.collection-text {}

.collection-text h3 {
    font-family: $bold_font_family; 
    font-size: 18px;
}

.collection-text p {
    font-size: 15px;
    margin-bottom: 15px;
}

.map-section{
   margin-top: 100px; 
}    

.map-wrapper {
    padding-bottom: 25px;
    // margin-bottom: 35px;
    // border-bottom: 1px dashed $black;
}

.map-wrapper iframe {
    width: 100%;
}

.address-row {}

.address-row p {
    margin-bottom: 20px;
}

.address-row strong {
    font-family: $bold_font_family; 
}

.adv {
   // background: #F5891F;
}

.adv img {
    float: left;
}

.adv .text {
    overflow: hidden;
    padding: 16px;
    color: $black;
    font-size: 36px;
    line-height: 1.07;
    font-family: $light_font_family; 
    span{
        padding-top: 15px;
    }
}

.social-fixed {
    position: absolute;
    right: 20px;
    top: 980px;
}

.social-fixed li {
    margin-bottom: 20px;
    text-align: center;
}

.social-fixed.top210 {
    top: 210px;
}

.social-fixed li a {
    color: #808080;
    font-size: 22px;
}

.social-fixed.active {
    position: fixed;
    top: 130px;
    z-index: 9999999;
}

.social-fixed.top210.active {
    position: fixed;
    top: 180px;
    z-index: 9999999;
}

.geelong-slogun {
    position: absolute;
    top: 0;
    left: -160px;
}

.geelong-slogun.pos1 {
    top: 420px;
    position: fixed;
    left: 100px;
    display: none;
}

/* Map */
#map {
    position: relative;
    padding-bottom: 58%;
    height: 0;
    overflow: hidden;
}

#map iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}

/* ==========================================================================
   Exhibitions Page
   ========================================================================== */

.main-section {
    padding: $main-section-padding-top 0;


    body.exhibitions &,  
    body.about &, 
    body.access &, 
    body.collection &,
    body.learn &,
    body.learn-programs &,
    body.learn-resources &,
    body.learn-artists-work &,
    body.learn-childrens-gallery &,
    body.prizes-print &,
    body.prizes &,
    body.support_update &,
    body.single-learn-keys &,
    body.learn-keys &,
    body.events &{
        padding-bottom: 90px;
    }
}

.main-section.pdtop50 {
    padding-top: $pdtop50;
}

.partner-blurb{
    padding: 80px 0;
}

.exhibition{

    .mbn{
        display: none;
    }
    .row{
        &--description{
            padding-top: 40px;
            @media only screen and (max-width: $mobile-breakpoint) {
                padding-top: 0px;
            }
        }
    
        &.sponsor{
            h4{
                //font-size: 39px;
                font-size: 16px;
                font-family: $bold_font_family;
                line-height: 1.2;

                & + p{
                    padding-top: 15px;
                }
            }
        }

        .col12--btn{
            padding-bottom: 10px;
        }

    }
    .white-box{
        padding-bottom: 0;
    }
    .main-section{
        padding-bottom: 100px;
    }
}

.partner-intro{

}

.accordion-wrapper{



    .accordion-header, .accordion-content .accordion-header {
        // &:first-child{
        //     &.activex{
        //         &:after{
        //             display: none;
        //         }
        //         h2{
        //             &:after {
        //                 background: url(../images/icon-arrow-down.png) center no-repeat;
        //                 background-size: 16px auto;
        //             }
  
        //         }
        //     }
        //     &.active{
        //         padding: 0;
        //          &:after{
        //             display: block;
        //         }
        //          h2{
        //             &:after {
        //                 background: url(../images/icon-arrow-right.png) center center no-repeat;
        //                 background-size: auto 14px;
        //             }
        //         }
        //     }
        // }

       // border-bottom: 1px dotted $black;
        position: relative;
        cursor: pointer;
        //margin-bottom: 35px;
        //transition: all .2s;
        transition: all .6s;
        
        &#principlal-government-partner{
            //box-shadow: $box-shadow-external-top;
        }

        h2, h3, h4 {
            //font-size: 26px;
            font-size: 38px;
            font-family: $light_font_family; 
            line-height: 1.5;
            padding: 15px 0;
            
            @media only screen and (max-width: $mobile-breakpoint) { 
                font-size: 30px;
            }

            &:after {
                content: "";
                display: block;
                width: 18px;
                height: 12px;
                background: url(../images/icon-arrow-right.png) center center no-repeat;
                background-size: auto 14px;
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                @media only screen and (max-width: $mobile-breakpoint) { 
                    right: 25px;
                }
            }
        }

        h3{
            font-size: 32px;
        }

        h4{
            font-size: 26px;
            margin-bottom: 0;
        }

        &.active{
            padding: 40px 0;
        
            @media only screen and (max-width: $mobile-breakpoint) {    
                padding: 15px 0;
            }
            

            h2, h3, h4{
                padding: 0;
                &:after {
                    background: url(../images/icon-arrow-down.png) center no-repeat;
                    background-size: 16px auto;
                }
            }

            &:after{
                display: none;
            }
            
          

        }


        &--child, &--grand-child{
            padding: 20px 0;
        }

        &--parent{
            &.active{
                .support_update &{
                    margin-bottom: 20px;
                    padding: 20px 0 20px;
                    box-shadow: $box-shadow-internal-bottom-accordion;
                }
            }
        }
    }

    .accordion-content {
        display: none;
        //margin-bottom: 100px;
        padding-bottom: 100px;
           
        &--child{
            padding-bottom: 0;
        } 

        &--grand-child{
            padding-bottom: 0;
        }    
        
        &--parent{
         padding-bottom: 0;
        }
       
        
        .logo-charity{
            display: block;
            max-width: 106px;
            margin-bottom: 28px;
        }

        &.active{
        overflow: hidden;
        position: relative;

            &:after{
                content: '';
                height: calc( 100% + 15px );
                width: calc( 100% + 30px );
                position: absolute;
                top: -5px;
                left: -15px;
                z-index: 100;
                box-shadow: $box-shadow-internal-bottom;
                pointer-events:none;
            }
        } 

        div{
            font-size: $font-size;
        }
    }

    .accordion-content.active {
        display: block;
    }

    .accordion-content p {
        margin-bottom: 22.1px;
    }

    .accordion-content p:last-child {
        margin-bottom: 0px;
    }

    .accordion-content p strong {
        font-family: $bold_font_family; 
    }

    .accordion-content p.pad-top {
        padding-top: 25px;
    }

    .accordion-content .color-green {
        //color: #00AF84;
    }

    .accordion-content  {
        h3{
            font-size: 18px;
            //font-family: $medium_font_family; 
            font-family: $light_font_family; 
            line-height: 1.2;
            margin-bottom: 20px;
            a{
                font-family: $light_font_family; 
            }
        }
    }

    .accordion-content h4 {
        margin-bottom: 20px;
    }

    .accordion-content h6 {
        font-size: 18px;
        font-family: $bold_font_family; 
        line-height: 1.2;
        margin-bottom: 0px;
        font-weight: normal;
    }

    .accordion-content h6.mgbottom25 {
        margin-bottom: 25px;
    }

    // .accordion-content ul.styled {
    //     margin-bottom: 25px;
    // }

    // .accordion-content ul.styled li {
    //     padding-left: 25px;
    //     position: relative;
    //     margin-bottom: 25px;
    // }
    
    .accordion-content ul.styled li {
        margin-bottom: 25px;
    }


    // .accordion-content ul.styled li:before {
    //     content: "-";
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    // }

    // .accordion-content ul.styled.nopd li {
    //     margin-bottom: 2px;
    // }

    .accordion-content ol {
        list-style: decimal;
        padding-left: 20px;
    }

    .accordion-content ol li {
        list-style: decimal;
        //padding-left: 75px;
        padding-left: 5px;
        margin-bottom: 25px;
    }

    #explore-the-collection ~ .accordion-content{
        overflow: visible;
    }

    #annual-reports ~ .accordion-content{
        ul{
            li{
                list-style: none;
            }
        }
    }

}

.toggled-text {
    // not sure why this was set - may need to update
    // max-height: 10em;
    line-height: 1.3em;
    font-size: 17px;
}

.toggled-text * {
    line-height: 1.3em;
    font-size: 17px;
    margin-bottom: 22.1px;
}

.btn-more {
    display: inline-block;
    font-size: 18px;
    color: #00AF84;
    background: url(../images/icon-read-more.png) right center no-repeat;
    padding-right: 20px;
    margin-top: 25px;
}

.btn-less {
    display: inline-block;
    font-size: 18px;
    color: #00AF84;
    background: url(../images/icon-read-less.png) right center no-repeat;
    padding-right: 20px;
    margin-top: 25px;
}

.accordion-content ul li {
    list-style: disc;
    margin-bottom: 4px;
}

.accordion-content ul {
    padding-left: 20px;
}

/* ==========================================================================
   Single Page
   ========================================================================== */
.slideshow-section.pad-top {
    padding-top: 50px;
}

.white-box {
    background: $white;
    //padding: 60px 70px;
    //padding: 60px 60px 60px 0px;
    padding: 60px 0; 
    position: relative;
}

.white-box.mb {
    //margin-bottom: 120px;
    margin-bottom: 50px;
}

.white-box{ 
    hr {
        height: 0;
        border: none;
        border-top: 1px dotted $black;
        margin: 28px 0;
        display: none;
        
        &.mtn {
            margin-top: 0px;
        }
        &.mbn {
        margin-bottom: 0px;
        }
    }

    .row + .row{
        margin-top: 40px;
    }

    hr:not(.mtn) + .row{
        padding-top: 40px;
    }
}




.main-content {
    color: $black;
}

.main-content h1 {
    font-size: 39px;
    font-family: $light_font_family; 
    line-height: 1.3;
   //line-height: 1;
    margin-bottom: 10px;
}

.main-content h2 {}

.main-content h3 {
    font-size: 18px;
    font-family: $bold_font_family; 
    line-height: 1.2;
    margin-bottom: 20px;
}

.main-content h4 {
    font-size: 16px;
    font-family: $bold_font_family; 
    line-height: 1.2;
    margin-bottom: 20px;
}

.main-content .date {
    font-size: 21px;
}

.main-content p {
    font-size: 16px;
    line-height: 1.2;
    color: $black;
    margin-bottom: 20px;
}

.main-content p.small {
    font-size: 16px;
    margin-bottom: 10px;
}

.main-content .btn-small {
    font-size: 18px;
    padding: 10px 11px 5px;
    width: auto;
    margin: 5px 0;
}

.main-content table.prices {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 10px;
}

.main-content table.prices tr td {
    padding: 3px 20px 3px 0;
}

.partners-logos {}

.partners-logos .col3 {
    margin-bottom: 15px;
}

.partner-blurb p {
    margin-bottom: 22px;
}

.partners-text {}

.partners-text .col3 {
    margin-bottom: 15px;
}

.partners-text h5 {
    font-size: 21px;
    font-family: $bold_font_family; 
    line-height: 1.2;
}

.catalogueBtn {
    font-size: 18px;
    font-family: $bold_font_family; 
    background-color: #9AE8DE;
    border: none;
    padding: 10px 14px 5px;
    margin-bottom: 10px;
    display: inline-block;
}

.share-btns {
    position: absolute;
    right: -38px;
    top: 100px;
}

.share-btns li {
    display: block;
    margin-bottom: 15px;
}

.related {}

.related h2 {
    font-size: 26px;
    font-family: $bold_font_family; 
    line-height: 1.2;
    border-bottom: 1px dotted $black;
    margin-bottom: 50px;
    padding-bottom: 8px;
}

.article-block .yellow-tr:after {
    border-color: transparent transparent #FFD649 transparent;
}

.article-block .pink-tr:after {
    border-color: transparent transparent #F177AE transparent;
}

.article-block .blue-tr:after {
    border-color: transparent transparent #47C3F1 transparent;
}

.instagram-block {
    background: $white;
    padding: 13px;
    margin-bottom: 30px;
}

.instagram-block img {
    display: block;
    margin-bottom: 15px;
}

.instagram-block p {
    padding-left: 25px;
    background: url(../images/icon-instagram-black.png) 0px 0px no-repeat;
}

/* ==========================================================================
   About
   ========================================================================== */
.theme-green {}

.theme-green .sub-menu {
    background: #00AF84;
}

p.lead {
    font-size: 26px;
    line-height: 1.2;
    margin-bottom: 20px;
}

.docs-list li .doc {
    margin-bottom: 70px;
}

.docs-list .doc {
    width: 80px;
    height: 100px;
    background: url(../images/icon-doc.png) center no-repeat;
    background-size: 80px 100px;
    text-align: center;
    line-height: 100px;
    color: $white;
    font-size: 20px;
    font-family: $bold_font_family; 
    float: left;
    margin-right: 32px;
}

.docs-list .doc-infos {
    overflow: hidden;
}

.docs-list .doc-infos p {
    font-size: 18px;
    line-height: 1.2;
}

.docs-list .doc-infos p strong {
    font-family: $bold_font_family; 
    font-weight: normal;
}

// .docs-list .doc-infos .color-green {
//     color: #00AF84;
// }

.docs-list2 li {
    margin-bottom: 35px;
}

.docs-list2 .doc {
    background: url(../images/icon-doc-blue.png) center no-repeat;
}

.docs-list2 .doc-infos p {
    line-height: 1.4;
}

.docs-list2 a {
    color: $link-colour !important;
}

.board-member {
    margin-bottom: 30px;
}

.board-member img {
    display: block;
    width: 100%;
}

.board-member .board-info {
    padding: 15px 0px;
    background: $white;
}

.board-member .board-info h3 {
    font-size: 18px;
    line-height: 1.2;
    font-family: $bold_font_family; 
    margin-bottom: 0;
}

.board-member .board-info p {
    font-size: 18px;
    line-height: 1.2;
}

.board-member .board-info a {
    color: $link-colour;
}

/* ==========================================================================
   Forms
   ========================================================================== */

.form-bucket{

}



.form_bold {
    font-family: $bold_font_family; 
    margin-bottom: 6px;
    display: block;
}

.form-bucket h1 {
    //padding: 15px 18px 50px 18px;
    padding-bottom: 30px;
    margin: 0;
    font-family: $light_font_family; 
    font-size: 24px;
    line-height: 1.2;
}

.form-bucket p.leading {
   // padding: 0px 18px 5px 18px;
   // margin-top: -20px;
  margin:0;
  padding: 0;
  margin-bottom: 50px;
}

.form-section-title {
    padding: 15px 18px;
    font-family: $bold_font_family; 
}

.form-section-content {
    padding: 40px 18px 50px 18px;
    
    @media only screen and (max-width: $mobile-breakpoint) {
        .row + .row {
            padding-top: 40px;
        }
    }
    

}

.form-bucket label {
    font-family: $bold_font_family; 
    margin-bottom: 6px;
    display: block;
}

.form-bucket .form-input, .popup-container .input {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    //background: #F3F1EE;
    background-color: $white;
    border: 0;
    height: 30px;
    padding: 0 15px;
    margin-bottom: 15px;
    line-height: 30px;
    font-family: Arial;
    font-weight: normal;
    letter-spacing: 0.4px;
    box-shadow: $box-shaddow-btn;

    &:hover, &:focus{
          box-shadow: $box-shaddow-btn-hover;  
    }

    /* font-family: $light_font_family; ,Arial; */
}

.form-bucket textarea.form-input {
    height: 150px;
    padding: 5px 10px;
}

.form-bucket .mgbottom5 {
    margin-bottom: 5px;
}

.form-bucket .form-input.error {
    background: #ff9999;
}

.form-bucket select.error {
    background: #ff9999 url(../images/dropdown_orange.png) center right no-repeat;
}

p.error {
    font-weight: bold;
    color: #f00;
}

p.prize-form {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

p.prize-form strong {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 10px;
}

.form-section:before,
.form-section:after {
    content: " ";
    display: table;
}

.form-section:after {
    clear: both;
}

.select2-container {
    margin-bottom: 15px;
}

.select2-container .select2-selection--single {
    display: block;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #F3F1EE;
    border: 0;
    height: 30px;
    border-radius: 0;
    line-height: 30px;
}

.select2-container .select2-selection--single .select2-selection__rendered {
    line-height: 30px;
}

.select2-container .select2-selection--single .select2-selection__arrow b {
    display: none !important;
}

.select2-container .select2-selection--single .select2-selection__arrow {
    height: 30px;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 30px;
    background: url(../images/dropdown.png) center center no-repeat #F4781F;
}

.custom-dropdown {
    border: 0;
    border-radius: 0;
    background: $white;
}

.select2-results__option {
    padding: 3px 8px;
}

.form-bucket select {
    letter-spacing: 0.5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    border-radius: 0;
    position: relative;
    z-index: 9;
    height: 30px;
    font-size: 14px;
    color:$link-colour;
    display: block;
    width: 100%;
    background: $white url(../images/dropdown_orange.png) center right no-repeat;
    margin-bottom: 15px;
    text-indent: 10px;
    box-shadow: $box-shaddow-btn;
    
    &:hover{
        box-shadow: $box-shaddow-btn-hover;  
    }

    &.state{
        width: calc(50% - 6px);
        float: left;
        & + .state{
            margin-left: 6px;
        }
    }
}

// .theme-blue .form-bucket select {
//     color: $link-colour;
//     letter-spacing: 0.5px;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     border: none;
//     border-radius: 0;
//     position: relative;
//     z-index: 9;
//     height: 30px;
//     font-size: 14px;
//     display: block;
//     width: 100%;
//     background: $white url(../images/dropdown_blue.png) center right no-repeat;
//     margin-bottom: 15px;
//     line-height: 30px;
//     font-family: Arial;
//     letter-spacing: 0.4px;
// }

.theme-blue .form-bucket select.error {
    background: #ff9999 url(../images/dropdown_blue.png) center right no-repeat;
}

.inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.inputfile + label {
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    display: block;
    overflow: hidden;
}

.inputfile:focus + label,
.inputfile.has-focus + label {
    outline: 1px dotted $black;
    outline: -webkit-focus-ring-color auto 5px;
}

.inputfile + label {
    color: #d3394c;
}

.inputfile + label {
    padding: 0;
}

.inputfile:focus + label,
.inputfile.has-focus + label,
.inputfile + label:hover {
    border-color: #722040;
}

.inputfile + label span,
.inputfile + label strong {}

.inputfile + label span {
    width: 57%;
    height: 30px;
    line-height: 30px;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
    font-family: $light_font_family; ;
    font-size: 16px;
    color: $black;
}

.inputfile + label strong {
    width: 43%;
    height: 30px;
    font-size: 16px;
    line-height: 30px;
    color: $white$white;
    font-family: $bold_font_family; 
    background-color: #EB792B;
    display: inline-block;
    text-align: center;
    padding: 0 5px;
}

.inputfile:focus + label strong,
.inputfile.has-focus + label strong,
.inputfile + label:hover strong {
    background-color: $black;
}

.dev .checks {
    margin-bottom: 20px;
}

.dev input[type=checkbox].custom-checkbox + label {
    float: left;
    margin-bottom: 10px;
    color: $white$white;
    line-height: 35px;
}

.dev input[type=checkbox].custom-checkbox.error + label::before {
    border: 1px solid red;
}

input[type=checkbox].custom-checkbox {
    display: none;
}

input[type=checkbox].custom-checkbox + label {
    font-family: $light_font_family; ;
    padding-left: 48px;
    line-height: 30px;
    cursor: pointer;
    font-weight: normal;
    position: relative;
    display: inline-block;
    transition: box-shadow .2s ease;
    &:hover{
        //color: red;
        &:before{
            box-shadow: $box-shaddow-btn-hover;  
        }
    }
}

input[type=checkbox].custom-checkbox + label strong {
    font-family: $bold_font_family; 
}

input[type=checkbox].custom-checkbox + label::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
      box-shadow: $box-shaddow-btn;
    top: 0;
    left: 0;
}

input[type=checkbox].custom-checkbox:checked + label::before {
    background-image: url(../images/tick-orange.png);
}

input[type=radio].custom-checkbox {
    display: none;
}

input[type=radio].custom-checkbox + label {
    font-family: $light_font_family; ;
    padding-left: 48px;
    line-height: 30px;
    cursor: pointer;
    font-weight: normal;
    position: relative;
    display: inline-block;
    &:hover{
        //color: red;
        &:before{
            box-shadow: $box-shaddow-btn-hover;  
        }
    }
}

input[type=radio].custom-checkbox + label strong {
    font-family: $bold_font_family; 
}

input[type=radio].custom-checkbox + label::before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    background-color: $white;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    box-shadow: $box-shaddow-btn;
    top: 0;
    left: 0;
}

input[type=radio].custom-checkbox:checked + label::before {
    background-image: url(../images/tick-orange.png);
}



.btn {
    // display: inline-block;
    // border: 0;
    // background: #EB792B;
    // color: $white;
    // min-width: 160px;
    // padding: 10px 10px 4px 10px;
    // font-family: $bold_font_family; 
}

input[type=radio].custom-checkbox.error + label::before {
    background-color: #ff9999;
}

.mgbottom10 {
    margin-bottom: 10px;
}

.volunteer_form_container .form-bucket h1 {
//    color: $white;
}

.theme-green .volunteer_form_container .form-bucket {
    //background-color: #73ddc2;
   // background: $light-grey;
}

.volunteer_form_container .form-section-title {
   // background-color: #29cda6;
}

.give_left_padding {
    padding-left: 18px;
}

.volunteer_form_container .row {
    margin-top: 30px;
}

.volunteer_form_container .row .row {
    margin-top: 15px;
}

input[name=other_language_description] {
    width: 90% !important;
    margin-left: 5.5%;
}

/* ==========================================================================
   Prizes
   ========================================================================== */

.page-intro {
    //margin-bottom: 160px;
    padding: 80px 0;
   // padding-bottom: 80px

    ul{
        padding-top: 20px;
        margin-left: 20px;
        li{
            list-style: disc;
            padding-bottom: 10px;
        }
    }
}

.page-intro h1 {
    font-family: $bold_font_family; 
    margin-bottom: 30px;
}

.sponsored {
    display: block;
    font-family: $bold_font_family; 
    font-size: 16px;
    margin-bottom: 10px;
}

.sponsor-logo {
    display: inline-block;
    margin-bottom: 16px;
    margin-right: 20px;
}

.prize-box, .prize-box-no-img {
    height: 440px;
    width: 100%;
    border: 0;
    color: #333;
    clear: none;
    display: block;
    float: left;
    font-size: .9em;
    line-height: 20px;
    margin: 0 6px 20px 0;
    padding: 10px;
    background: $white;
    overflow: hidden;
}

.prize-box:hover, .prize-box-no-img:hover {
    background: #e1e1e1;
}

.prize-image {
    display: block;
    height: 250px;
    margin: 0 0 10px 0;
    position: relative;
}

.prize-text {
    display: block;
    height: 150px;
    margin: 20px 0 10px 0;
    position: relative;
}

.prize-text h3 {
    font-size: 15px;
    font-weight: normal;
    line-height: 20px;
}

.prize-text .collection-link {
    position: absolute;
    bottom: 0px;
}

.prize-image img {
    position: absolute;
    bottom: 0px;
    max-height: 250px;
}

/* ==========================================================================
   Collection
   ========================================================================== */

.btn-search {
    margin-bottom: 40px;
}

/* ==========================================================================
   Keys to the Collection
   ========================================================================== */

.banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    position: relative;
}

.banner .table {
    position: relative;
    z-index: 2;
}

.banner h1 {
    font-family: $light_font_family; 
    font-size: 150px;
    line-height: 1.2;
    color: $white !important;
   //text-shadow: 4px 4px 0 rgba(204, 204, 204, 0.5);
    margin-bottom: 6%;

    @media only screen and (max-width: $mobile-breakpoint){
        font-size: 60px; 
        line-height:1.2; 
        color:#fff!important; 
        text-shadow: none; 
        margin-bottom:6%;  
    }
}

.unlock-btn {
    display: inline-block;
    text-align: center;
    font-family: $bold_font_family; 
    font-size: 20px;
    color: $white;
    line-height: 1.5;
}

.unlock-btn span {
    display: inline-block;
    margin-bottom: 8px;
}

.page-locked .banner::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $light-grey;
    // background: #49C6FF;
    opacity: 0.5;
    z-index: 0;
}

.gallery-cont {
    padding-top: 50px;
    //border-top: 1px dotted $black;
}

.filter-btn {
    margin-bottom: 30px;
}

.filter-btn a {}

.filter-btn a i {
    display: inline-block;
    padding-left: 5px;
}

.gallery-filters {
    text-align: right;
    margin-bottom: 60px;
}

.gallery-filters button {
    background: none;
    border: 0;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6%;
    padding-bottom: 25px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 40px;
    &:hover, &.active{
        color: $site-colour;
    }
}

.gallery-filters button:first-child {
    margin-left: 0;
}

.gallery-filters button.active {
    color: $white;
}

.gallery-filters button.key1 {
    background-image: url(../images/filter-key4.png);
}

.gallery-filters button.key1.active {
    background-image: url(../images/filter-key4-white.png);
}

.gallery-filters button.key2 {
    background-image: url(../images/filter-key4.png);
}

.gallery-filters button.key2.active {
    background-image: url(../images/filter-key4-white.png);
}

.gallery-filters button.key3 {
    background-image: url(../images/filter-key4.png);
}

.gallery-filters button.key3.active {
    background-image: url(../images/filter-key4-white.png);
}

.gallery-filters button.key4 {
    background-image: url(../images/filter-key4.png);
}

.gallery-filters button.key4.active {
    background-image: url(../images/filter-key4-white.png);
}

.gallery-filters button.key5 {
    background-image: url(../images/filter-key4.png);
}

.gallery-filters button.key5.active {
    background-image: url(../images/filter-key4-white.png);
}

.gallery-filters{
    &--channel{
        button{
            &.key1{
                &.active{
                    background-image: url(../images/filter-key4.png);
                    color: $site-colour;
                }
            }
        }
    }
}

.artwork-image {
    margin-bottom: 100px;
}

.mfp-image-holder .mfp-close {
    right: -35px;
    text-align: right;
    width: 30px;
    top: 25px;
    font-size: 40px;
    font-family: $light_font_family; ;
    padding-right: 0px;
    opacity: 1;
}

.artwork-info-panel {
    background: $white;
    padding: 20px 20px 10px 20px;
    margin-bottom: 100px;
}

.artwork-info-panel h2 {
    font-family: $bold_font_family; 
    font-size: 26px;
    line-height: 1.2;
}

.table-style {
    font-size: 18px;
}

.table-style th {
    text-align: left;
    font-family: $bold_font_family; 
    font-weight: normal;
}

.table-style td {
    padding-bottom: 4px;
}

.artwork-info-panel .table-style {
    margin-bottom: 100px;
}

/* ==========================================================================
   Support
   ========================================================================== */

.simple-table {
    margin-bottom: 20px;
}

.simple-table td {
    padding: 0 60px 5px 0;
}

.logos {}

.logos .col20 {
    width: 20%;
    float: left;
}

.logos .logo-link {
    display: block;
    width: 100%;
    max-width: 200px;
    height: 70px;
    float: left;
    margin-bottom: 30px;
    margin-right: 20px;
}

.logos .logo-link:last-child {
    margin-right: 0;
}

.logos .logo-link img {
    max-height: 100%;
    width: auto;
}

/* ==========================================================================
   Page Themes
   ========================================================================== */

.theme-orange {}

.theme-orange .accordion-content a {
    color: $link-colour;
}

// .theme-orange .header .sub-menu {
//     background-color: #F4781F;
// }

.qq-upload-delete {
    width: 59%;
    height: 30px;
    font-size: 16px !important;
    line-height: 30px;
    color: $white !important;
    font-family: $bold_font_family; 
    background-color: #EB792B;
    text-align: center;
    padding: 0 5px;
    display: block !important;
}

.qq-upload-delete:hover {
    background-color: $black;
}

/* .theme-orange .header .sub-menu-level2 {background-color:#F4F2EF;} */
// .theme-orange .header .sub-menu-level2 {
//     background-color: #f8ae79;
// }2

.theme-orange .form-bucket {
    //background-color: #F5C8A5;
   // background: $light-grey;
}

.theme-orange h1 {
    //color: #EF6414;
}

.theme-orange p.lead {
    //color: #F4781F;
}

.theme-orange .form-section-title {
    //background-color: #F1AE7B;
}

.theme-orange .select2-container .select2-selection--single .select2-selection__rendered,
.theme-orange .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: #EF6414;
}

.theme-orange .select2-container .select2-selection--single .select2-selection__arrow {
    background-color: #F4781F;
}

.theme-orange .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #F4781F;
}

.theme-orange .btn {
//    background: #EB792B;
  //  color: $white;
}

.theme-orange input[type=checkbox].custom-checkbox:checked + label::before {
    background-image: url(../images/tick-orange.png);
}

.theme-yellow {}

// .theme-yellow .header .sub-menu {
//     background-color: #FCD43A;
// }

.theme-yellow .btn-more {
    color: #666;
    background-image: url(../images/icon-read-more-grey.png);
}

.theme-yellow .btn-less {
    color: #666;
    background-image: url(../images/icon-read-less-grey.png);
}

// .theme-blue.theme-light-blue .header .sub-menu {
//     background-color: #B2E8FE;
// }

.theme-blue {}

.theme-blue .accordion-content a {
    //color: $link-colour;
}

// .theme-blue .header .sub-menu {
//     background-color: #54C6FE;
// }

// .theme-blue .header .sub-menu-level2 {
//     background-color: #F4F2EF;
//     background-color: #98ddfe;
// }

.form-bucket{

    .form-section{
        background: $light-grey;
    }
}


.theme-blue .form-bucket {
   // background-color: #B2E8FE;
    //background: $light-grey;
}

.theme-blue h1 {
 //  color: #EF6414;
}

.theme-blue p.lead {
    //color: #54C6FE;
}

.theme-blue .form-bucket h1 {
    //color: $white;
}

.theme-blue .form-section-title {
 //   background-color: #90DDFE;
 background-color: transparent;
}

.theme-blue .select2-container .select2-selection--single .select2-selection__rendered,
.theme-blue .select2-container--default .select2-selection--single .select2-selection__placeholder {
    color: $link-colour;
}

.theme-blue .select2-container .select2-selection--single .select2-selection__arrow {
    background-color: #54C6FE;
}

.theme-blue .select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: #54C6FE;
}

.theme-blue .btn {
    //background: #54C7FE;
    //color: $white;
    color: $black;
    background: $white;
}

.theme-blue input[type=checkbox].custom-checkbox:checked + label::before,
.theme-blue input[type=radio].custom-checkbox:checked + label::before {
    background-image: url(../images/tick-blue.png);
}

.theme-blue p.lead.text-black {
    color: $black;
}

.theme-blue .article-block .blue-row {
    background: #54C6FE;
}

.theme-blue2 .main {
//    background-color: #9CDCF6;
}

body{
    &.learn-keys, &.single-learn-keys{
        .main{
            background-color: $light-grey;
        }
    }

    &.single-learn-keys{
        .main{
            padding-top: 100px;
        }
    }
}


.theme-blue2 .accordion-content a {
    color: $link-colour;
}

// .theme-blue2 .header .sub-menu {
//     background-color: #54C6FE;
// }

.theme-blue2 .header .sub-menu-level2 {
    background-color: #F4F2EF;
}

.theme-blue2 h1 {
    color: $link-colour;
}

.theme-blue2 p.lead {
   // color: $link-colour;
}

.theme-blue2 .btn {
    background: #54C7FE;
    color: $white;
}

.theme-blue2 p.lead.text-black {
    color: $link-colour;
}

.theme-blue2 .article-block .blue-row {
    background: #54C6FE;
}

.theme-green {}

.theme-green .accordion-content a {
   color: $link-colour;
}

// .theme-green .header .sub-menu {
//     background-color: #39AF84;
// }

// .theme-green .header .sub-menu-level2 {
//     background-color: #6adcb3;
// }
r
.theme-green .form-bucket {
    background-color: #B2E8FE;
}

.theme-green h1 {
  //  color: #EF6414;
}

.theme-green p.lead {
  //  color: #54C6FE;
}

// .theme-green .btn,
// .theme-green a.btn {
//     background: #39AF84;
//     color: $white;
//     text-align: center;
//     line-height: 20px;
//     padding-top: 10px;
//     margin-bottom: 20px;
// }

.theme-green p.lead.text-black {
    color: $black;
}

.theme-green .article-block .blue-row {
    background: #54C6FE;
}

/* ==========================================================================
   Who's Who
   ========================================================================== */
.whoswho-map #whoswhoMap {
    height: 500px;
}

.whoswho-vote {}

.whoswho-vote p.thankyou-msg {
    color: $black;
    font-family: $bold_font_family; 
    text-align: center;
    background: $white;
    width: 50%;
    margin: 0 auto;
    padding: 20px 0;
    line-height: 1;
    margin-bottom: 20px;
    display: none;
}

.whoswho-schools h4 {
    margin-bottom: 0;
}

.whoswho-schools p {
    margin-bottom: 10px;
}

.whoswho-schools ul {
    margin-bottom: 20px;
}

.autocomplete {
    display: none;
    position: absolute;
    background: white;
    margin-top: -15px;
    width: calc(100% - 40px);
    padding: 10px 15px;
}

.autocomplete li {
    display: block;
}

.autocomplete li a {
    display: block;
    padding: 3px 0;
}

.autocomplete li a:hover {
    color: $white;
    text-decoration: none;
    background: black;
}

/* ==========================================================================
   Learn Pages
   ========================================================================== */

.subscribe-popup.learn {
    background: #54C6FE;
    border: 1px solid #3984A9;
}

.subscribe-popup.learn .btn-cancel {
    background: #aaaaaa;
}

.subscribe-popup.learn .btn {
    background: #666666;
}

.p_logo_bg {
    width: auto;
    height: auto;
    //padding: 10px !important;
    padding: 10px;

    &:first-child{
        padding-left: 15px;
    }

}

.p_logo_bg > div {
    width: auto;
    height: 110px;
}

.p_logo_bg img {
    opacity: 0;

    /* width: 100%; */
    /* height: 100%; */
    max-height: 100%;
    max-width: 100%;
}

/* ==========================================================================
   Media Queries
   ========================================================================== */
/*
 * Tablet
 */
@media only screen and (max-width: 1220px) {
    .social-fixed {
        display: none;
    }

    .col-lg-15 {
        width: 20%;
        float: left;
    }
}

@media only screen and (max-width: 1150px) {

   

    .container {
        width: auto;
        padding: 0  $margins-mobile;
    }

    .row {
        margin: 0 (-$margins-mobile);
    }

    .row{ 
        & > div {
            padding: 0 $margins-mobile;
        }
        // &8 > div{
        //     padding: 0 8px;
        // }
    }


    .adv .text {
        font-size: 26px;
    }

    .share-btns {
        display: none;
    }
}

@media only screen and (max-width: 1200px) {
    .header .menu li + li {
        margin-left: 40px;
    }
}

@media only screen and (max-width: $tablet-portrait-breakpoint) {
    /* Tablet grid */
    .col-md-15 {
        width: 20%;
        float: left;
    }

    .col-md-12 {
        width: 100%;
    }

    .col-md-11 {
        width: 91.666666666667%;
    }

    .col-md-10 {
        width: 83.333333333333%;
    }

    .col-md-9 {
        width: 75%;
    }

    .col-md-8 {
        width: 66.666666666667%;
    }

    .col-md-7 {
        width: 58.333333333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-5 {
        width: 41.666666666667%;
    }

    .col-md-4 {
        width: 33.333333333333%;
    }

    .col-md-3 {
        width: 25%;
    }

    .col-md-2 {
        width: 16.666666666667%;
    }

    .col-md-1 {
        width: 8.3333333333333%;
    }

    /* Helpers */
    .hidden-tablet {
        display: none;
    }

    .visible-tablet {
        display: block;
    }

    .header .menu li + li {
        margin-left: 20px;
    }

    .slideshow-section .caption {
        h1,h2{
        //      font-size: 55px;
        }
    }

    .adv img {
        float: none;
        width: 100%;
        height: auto;
    }

    .adv .text {
        overflow: visible;
    }

    .white-box {
        padding: 20px;
    }

    .prize-box, .prize-box-no-img {
        height: 500px;
    }

    .prize-text {
        height: 200px;
    }
}

/*
 * Mobile
 */
@media only screen and (max-width: $mobile-breakpoint) {
    .p_logo_bg {
        width: auto;
        height: auto;
        padding: 10px !important;
    }

    .p_logo_bg > div {
        width: auto;
        height: 100px;
    }

    .prize-box, .prize-box-no-img {
        height: 460px;
    }

    .prize-text {
        height: 150px;
    }

    p.lead {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .slideshow-section {
        margin-bottom: 120px;
        position: relative;
        height: 400px;
    }

    .slideshow-section .slide {
        width: 100%;
        position: relative;
        height: 100%;
    }

    /* Mobile grid */
    .col-sm-15 {
        width: 20%;
        float: left;
    }

    .col-sm-12 {
        width: 100%;
    }

    .col-sm-11 {
        width: 91.666666666667%;
    }

    .col-sm-10 {
        width: 83.333333333333%;
    }

    .col-sm-9 {
        width: 75%;
    }

    .col-sm-8 {
        width: 66.666666666667%;
    }

    .col-sm-7 {
        width: 58.333333333333%;
    }

    .col-sm-6 {
        width: 50%;
    }

    .col-sm-5 {
        width: 41.666666666667%;
    }

    .col-sm-4 {
        width: 33.333333333333%;
    }

    .col-sm-3 {
        width: 25%;
    }

    .col-sm-2 {
        width: 16.666666666667%;
    }

    .col-sm-1 {
        width: 8.3333333333333%;
    }

    /* Helpers */
    .hidden-mobile {
        display: none;
    }

    .visible-mobile {
        display: block;
    }

 

    .header .menu {
        display: none;
        float: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 9999;
        margin-top: 0;
        background: $white;
        padding: 10px 0;
        box-shadow:  $box-shadow-external-bottom;


        &:after{
            content: '';
            top: 0;
            left: 0;
            display: block;
            position: fixed;
            width: 100%;
            height: 100%;
            background: $white;
            z-index: -1;
        }
    }

    .header .menu-mobile.active {
        display: block;
        // position: relative;
        // top: $header-without-submenu;
        z-index: -1;
    }

    .header .menu li {
        //float: none;
        display: block;
    }

    .header .menu li + li {
        margin-left: 0px;
    }

    .header .menu li a {
        display: block;
        padding: 5px $margins-mobile;
    }

    .catalogueBtn {
        margin-top: 10px;
    }

    .sub-menu {
        display: none;
    }

    .sub-menu-level2 {
        display: none;
    }

    .menu-icon {
        display: block;
    }

    .slideshow-section {
      //  margin-bottom: 50px;
      margin-bottom: 0px;
    }

    .slideshow-section .caption {
       // display: none;
    }

    .content-block {
        margin-bottom: 35px;
    }

    .header-block h2 {
        font-size: 26px;
        margin-bottom: -5px;
    }

    .header-block .date {
        position: relative;
        bottom: auto;
        right: auto;
        display: block;
        margin-top: 15px;
    }

    .map-wrapper iframe {
        height: 250px;
    }

    .accordion-header h2 {
        font-size: 20px;
    }

    .partners-logos img {
        display: block;
        margin: 0 auto;
    }

    .subscribe-popup {
        width: 95%;
        padding: 15px;
        max-width: 100%;
    }

    .subscribe-popup h2 {
        margin: 30px 0;
    }

    .state {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 600px) {
    .p_logo_bg > div {
        width: auto;
        height: 75px;
    }

    .slideshow-section .caption h1 {
//        font-size: 26px;
    }

    .slideshow-section .caption h2 {
    //    font-size: 16px;
        font-family: $light_font_family; 
    }

    .slideshow-section {
        /* margin-bottom: 120px; */

        position: relative;
        height: auto;
    }

    .slideshow-section .slide {
        width: 100%;
        position: relative;
        height: 100%;
    }

    /* Mobile grid */
    .col-sm-15 {
        width: 20%;
        float: left;
    }

    .col-xs-12 {
        width: 100%;
    }

    .col-xs-11 {
        width: 91.666666666667%;
    }

    .col-xs-10 {
        width: 83.333333333333%;
    }

    .col-xs-9 {
        width: 75%;
    }

    .col-xs-8 {
        width: 66.666666666667%;
    }

    .col-xs-7 {
        width: 58.333333333333%;
    }

    .col-xs-6 {
        width: 50%;
    }

    .col-xs-5 {
        width: 41.666666666667%;
    }

    .col-xs-4 {
        width: 33.333333333333%;
    }

    .col-xs-3 {
        width: 25%;
    }

    .col-xs-2 {
        width: 16.666666666667%;
    }

    .col-xs-1 {
        width: 8.3333333333333%;
    }
}

@media only screen and (max-width: 420px) {
    .subscribe-popup.dev input[type=checkbox].custom-checkbox + label::before {
        width: 20px;
        height: 20px;
    }

    .subscribe-popup.dev input[type=checkbox].custom-checkbox + label {
        float: left;
        margin-bottom: 10px;
       // color: $white;
        line-height: 24px;
        font-size: 16px;
        margin-bottom: 4px;
        padding-left: 36px;
    }

    .subscribe-popup.dev input[type=checkbox].custom-checkbox.error + label::before {
        border: 1px solid red;
    }

    .subscribe-popup h2 {
        margin: 20px 0;
        font-size: 16px;
    }
}