/* Css to be merged back into main document */

/* events - submenu colour */

body.with-submenu.events .header .sub-menu {
   // background:#7BAFDE;
}


body.with-submenu.events .header .sub-menu{
 //background:#7BAFDE;;
} 

.accordion-content ul li{
	    list-style: none;
}



body.with-submenu.events .header .sub-menu-level2{
 //background:#A9CAE8;
} 


body.with-submenu.exhibition .header .sub-menu-level2{
 //   background: #c2f1eb;
} 

.toggled-text {
    max-height: 100%;
}

/* a.scroll-to:focus {font-family: $bold_font_family; } */

/* Exhibition-single */
.partners-text h5{
	padding-bottom: 25px;
}

.board-member h3{	
	width: 90%;
	
}

.article-block .blue-row{
	
}

/* Social side icons */

.social-fixed.top210{
	z-index: 10;
}

/* education / k-12 programs */
section.main-section.k-12 a {
	 color: $black;
	&:hover{    
		color: $link-colour;
	}
}

a.pdf-link{
	color: $link-colour;
}


strong{
    //font-size: 18px;
    font-size: inherit;
    font-family: $bold_font_family; 
    line-height: 1.2;
    margin-bottom: 0px;
    font-weight: normal;
}
.sponsor strong, .sponsor p{
    font-size: 15px;
    font-family: $light_font_family; 
    line-height: 1.2;
    margin-bottom: 0px;
    font-weight: normal;
    margin-bottom:20px;
}

.sponsor {

	&.row{
		display: inline-block;
		width: 100%;

		& + row{
			margin-bottom: 30px;
		}
	}
}
section.main-section.k-12 .article-block .blue-row{
	background-color: $link-colour;
}

.theme-blue .accordion-content .theme-blue .accordion-content a.learn-subscribe{
	color: $link-colour;
}

.btn-grey.btn-small.learn-subscribe{
	color: #ffffff;
}


/* Keys to the Collection */

img.single-image {
    max-height: 600px;
}

body.theme-blue2{
	padding-bottom: 0px;
}

/*gallery filter keys */

.gallery-filters button.key1 {background-image:url(../images/Faces_key.png);}
.gallery-filters button.key1.active {background-image:url(../images/Faces_key_white.png);}
.gallery-filters button.key2 {background-image:url(../images/Spaces_key.png);}
.gallery-filters button.key2.active {background-image:url(../images/Spaces_key_white.png);}
.gallery-filters button.key3 {background-image:url(../images/Form_key.png);}
.gallery-filters button.key3.active {background-image:url(../images/Form_key_white.png);}
.gallery-filters button.key4 {background-image:url(../images/Colour_key.png);}
.gallery-filters button.key4.active {background-image:url(../images/Colour_key_white.png);}
.gallery-filters button.key5 {background-image:url(../images/Stories_key.png);}
.gallery-filters button.key5.active {background-image:url(../images/Stories_key_white.png);}

button.key {
    background-size: contain;
    background-origin: content-box;
    background-position: 0px 20px;
}

.accordion-content.active .gallery-cont{
	border: none;
}

/* artists at work */
.pad-top{
	padding-top: 100px;
}


.article-block.artists:after {
	position: absolute;
	margin-top: -55%;
	left: 45%;
	content: url("../images/media-arrow.png");
	opacity: 0.5;
}


.slide.video-overlay:after{
	position: absolute;
	margin-top: -38%;
	left: 45%;
	content: url("../images/video-triangle.png");
	opacity: 0.5;
}


.slide.video-overlay {
    z-index: 10;
    position: absolute;
}

.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* K-12 resources */

// .article-block .text.without-tr a {
//     color: #000000;
// }

.theme-blue h1{
	color:#000000;
}

.blue-link a{
	color: $link-colour;
}

/* support */

.logos .logo-link{height: 150px;}

/* Childrens Gallery */

.childrens-gallery .slide.video-overlay:after {
    position: absolute;
    margin-top: 25%;
    left: 45%;
    content: url("../images/video-triangle.png");
    opacity: 0.5;
}

.childrens-gallery .slide.video-overlay{
	background-size: cover;
    width: 100%;
    height: 100%;
}



@media only screen and (max-width: $mobile-breakpoint) {

	.slideshow-section .video-overlay.slide{ height: 200px;}
	
	.slide.video-overlay:after {
	    position: absolute;
	    margin-top: -38%;
	    left: 45%;
	    content: url("../images/media-arrow.png");
	    opacity: 0.5;
	    width: 14%;
	    height: auto;
	}
	.videoWrapper{
		display: none;
	}
	
}

