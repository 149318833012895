/* ==========================================================================
   Header
   ========================================================================== */
$header_font_size: 15px;

.header .menu li {
    float: left;


    @media only screen and (max-width: $mobile-breakpoint) {
        //float: none;
        width: 100%;
    }
    & + li {
        margin-left: 60px;

        @media only screen and (max-width: $mobile-breakpoint) {
            margin-left: 0;
        }
    }

    a {
        display: block;
    }

    ul.mobileSubNav {
        width:100%;

        display: none;

        li.mobile { 
            width: 100%; 
            margin-left: 30px;
            //text-indent: 10px; 
            background: url(/resources/images/icon-arrow-right.png) left center no-repeat; 
            background-size:8px 12px; 
            //background-position-y: 5px;
            background-position-y: 5px;

            & + li{ 
                margin-left: 30px; 
                padding-right: 20px;
            }

            a{ 
                display: block; 
                padding: 5px 15px 5px 25px;
                //height: 30px;
            }
        } 
    }
}


.header .menu li.active > a {
    font-family: $medium_font_family;
    color: $link-colour;
}

// .header .sub-menu-level2{background: #F4F2EF; text-align: center; padding: 13px 0;}
// .header .sub-menu-level2 ul{}
// .header .sub-menu-level2 ul li{ display: inline-block; margin: 0 25px; }
// .header .sub-menu-level2 ul li a{ font-size: 17px; line-height: 1.2; padding: 4px 2px 0px; display: block; }
// .header .sub-menu-level2 ul li.active a{ font-family: $bold_font_family;  }

.header .sub-menu-level2.no-sub {
    display: none;
}

.header .color-blue {
    background: #7BAFDE;
}

.header .color-light-blue {
    background: #B0CEE9;
}

.menu-icon {
    float: right;
    display: none;
    margin-top: 31px;
    margin-left: 0;
    width: 24px;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    
    @media only screen and (max-width: $mobile-breakpoint) {
        margin-top: 23px;
        display: block;
    }

}

.menu-icon:before, .menu-icon:after,
.menu-icon .menu-icon__text {
    background: #000;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
    border-radius: 3px;
    content: '';
    display: block;
    height: 3px;
    margin: 4px 0;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    -o-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    width: 100%;
}

.menu-icon{
    &.active{
        margin-top: 30px;
        span{
            visibility: hidden;
            opacity: 0;
        }

        &:after{
            top: 0;
            transform: rotate(45deg);
             position: absolute;
        }
        
        &:before{
            top: 0;
            transform: rotate(-45deg);
            position: absolute;
        }
    }
}

.menu-icon .menu-icon__text {
    text-indent: -9999px;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: #ffffff;
    font-size: $header_font_size;

    &__main {
        max-height: 90px;

        .with-submenu & {
            max-height: 70px;
        }
    }

    .logo {
        float: left;
        // margin: 15px 0;
        // width: 110px;
        // height: 60px;
    
        max-height: 100%;
        display: block;
        width: auto;

        img {
            height: 70px;
            display: block;

            &.logo-svg{
                display: block;
                html.ie &, html.edge &{
                   display: none; 
                }
            }

            &.logo-png{
                display: none;
                html.ie &, html.edge &{
                    display: block; 
                }
            }
        }     
    }

    .menu {
        &-mobile{
            display: none;
            @media only screen and (max-width: $mobile-breakpoint) {
                display: block;
                position: relative;
            }
        }
        @media only screen and (min-width: $mobile-breakpoint) {
            bottom: 11px;
        }

        position: absolute;
        right: 0;

        @media only screen and (max-width: 1150px) {
            //right: 0;
            right: 30px;  
        }

        //float: right; 
        //margin-top: 34px;

        a {
            font-size: $header_font_size;
            line-height: 19px;  
        }

        @media only screen and (min-width: 500px) and (max-width: $mobile-breakpoint) {
            li {
                ul.mobileSubNav{ 
                    li.mobile{
                         background-position-y: 10px;
                    }
                }

                a{
                    font-size: 20px;
                    line-height: 26px;
                }
            }
        }

        li + li {
           //margin-left: 24px;
        }
    }

    .sub-menu {
        background: $white;
        text-align: right;
        padding: 13px 0;
        height: 37px;
        z-index: -1;
        position: relative;

        &-level2 {
            z-index: -2;
            background-color: $white;
        }

        ul {
            li {
                display: inline-block;
                //margin: 0 25px;
                margin-left: 25px;
				
				.support_update &{
					//margin-left: 22px;
                    margin-left: 15px;
                    @media only screen  and (max-width: 1115px) {
                        margin-left: 5px;
                        font-size: 14px;
                    }
				}

                a {
                    font-size: $header_font_size;
                    line-height: 1;
                    padding: 0px;
                    display: block;
                }

                &.active {
                    a {
                        //font-family: $medium_font_family;
                        font-family: $light_font_family;
                        color: $link-colour;
                    }
                }
            }
        }
    }
    .support_update &{
        .sub-menu {
            ul {
                li {
                    //margin-left: 22px;
                    margin-left: 15px;
                    @media only screen  and (max-width: 1115px) {
                        margin-left: 5px;
                        font-size: 14px;
                    }
                }
            }
            @media only screen  and (max-width: 1125px) {
                    height: 60px;
            }
        }
    }
}