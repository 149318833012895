.drop-shaddow{ 
    &--bottom, &--both{ 
      overflow: hidden;
      position: relative;
      &:after{
        content: '';
        height: calc( 100% + 15px );
        width: calc( 100% + 30px );
        position: absolute;
        top: -5px;
        left: -15px;
        z-index: 100;
        box-shadow: $box-shadow-internal-bottom;
        pointer-events:none;
      }
    }

    &--top{
      box-shadow: $box-shadow-external-bottom;
 
    }

    &--both{
      position: relative;

      &:before{
        content: '';
        height: calc( 100% + 15px );
        width: calc( 100% + 30px );
        position: absolute;
        top: 0px;
        left: -15px;
        z-index: 100;
        box-shadow: $box-shadow-internal-top;
        pointer-events:none;
      }
    }
}