// caption

div.heroCaption {
	position: absolute;
	opacity: 0;
	background: #666666;
	/* 	width: 300px; */
	height: auto;
	//bottom: -140px;
	bottom: 0;
	right: 0;
	/* 	border: 1px solid #808080; */
	z-index: 2;

	display: none;

	.captionContent{
		padding: 10px;
		position: relative;
		display: block;
		background: #fff;
		height: 100%;
		-webkit-border-radius: 0px;
		-moz-border-radius: 0px;
		border-radius: 0px;
		border: #adaab1 solid 1px;
		opacity: 1;


		&:after {
		    display: block;
		    content: '';
		    position: absolute;
		    border-style: solid;
		    border-width: 0 0 20px 18px;
		    border-color: #FFFFFF transparent;
		    display: block;
		    width: 0;
		    z-index: 1;
		    top: -17px;
		    right:0px;
		}
		&:before {
			content: '';
			position: absolute;
			border-style: solid;
			border-width: 0 0px 20px 18px;
			border-color: #adaab1 transparent;
			display: block;
			width: 0;
			z-index: 0;
			top: -20px;
			right: -1px;
		}

		p{
			color: #000;
			font-size: 12px;
		}
	}
}
