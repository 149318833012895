// _social.scss
ul.social-footer{
	  padding-top: 5px;
	li{
		line-height: 20px;
		display: inline;
		a{
			display: inline-block;
			//display: inline;
            //font-size: 16px;
            padding-right: 9px;

            @media only screen and (max-width: $mobile-breakpoint) { 
           		padding-right: 9px;
            }

			vertical-align: top;
			.social-icon{
				height: 20px;
				background-size: cover;
				background-repeat: no-repeat;
				background-position: center center;
				display: block;

				&-facebook{

					background-image: url(../images/social/2x/facebook-1@2x.png);
					width: 10px;
				}	
				&-twitter{
					background-image: url(../images/social/2x/twitter-1@2x.png);
					width:20px;
				}
				&-instagram{
					background-image: url(../images/social/2x/instagram-1@2x.png);
					width: 19px;
				}
				&-youtube{
					background-image: url(../images/social/2x/youtube-1@2x.png);
					width:18px;
				}
			}
		}
	}
}