// file uploader

#file-uploader{
	
	&2, &4, &.file-uploader1{
	
		.qq-upload-drop-area{
			background: $white;
		}
		.qq-upload-button{
			min-width: 175px;
			background: $white;
			color: $link-colour;
			box-shadow: $box-shaddow-btn;
			font-family: $light_font_family;
			padding: 12px 16px;
			font-size: 17px;
			line-height: 1;
			display: inline-block;
			text-align: center;
			border: 0px;
			height: auto;
		}
	}
}