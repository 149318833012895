// slider 

.slideshow-section{
	.slider{

		opacity: 0;
		transition: opacity .3s ease;

		&.slick-initialized{
			opacity: 1;
		}

		&.hero{
			max-width: 2500px;
	    	margin: 0 auto;
			height: calc(100vh - 70px);
			&.slick-transition{
				.slick-current{
					//margin-left: 16px;
					box-shadow: $box-shaddow-slide;
				}
			}
		}	

		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
				.slick-slide{
					height: 100%;
					width: 100%;
					position: relative;
					transition: transform 0.5s linear;
  					transition-delay: 0.5s;
					opacity: 0;

					//transition: opacity .3s ;
					&.slick-current{
						opacity: 1;	
					}

					&--next{
					//	left: -100%;
						opacity: 0;
					}
					&--prev{
							opacity: 0;
					}
					
					

					// &.slide--landscape{
					// 	img{
					// 		width: 100%;
					// 		height: auto;
					// 	}
					// }

					// &.slide--portrait{
					// 	//padding-top: 50px;
					// 	//padding-bottom: 50px;
					// 	img{
					// 		max-height: 1000px;
					// 		width: auto;
					// 	}
					// }

					img{
						height: 100%;
						width: auto;

						transition: box-shadow .3s;
					}

					@media only screen and (max-width: $mobile-breakpoint) {
						height: 100%;
						max-height: 400px;
						img{
							height: auto;
							width:auto;
						}	
					}
				}
			}
		}	
	}

	&--bottom{
		position: absolute;
	    top: $header-without-submenu;

	    body.with-submenu &{
			top: $header-with-submenu; 
			@media only screen and (max-width: $mobile-breakpoint) {
		    	top: $header-without-submenu;
		    }
	    }

	     body.with-submenu .main-section  &{
			top: $header-with-submenu-small + $main-section-padding-top; 
			@media only screen and (max-width: $mobile-breakpoint) {
		    	top: $header-without-submenu;
		    }
	    }

	   	body.with-submenu .main-section.pdtop50  &{
			top: $header-with-submenu + $pdtop50; 
			@media only screen and (max-width: $mobile-breakpoint) {
					top: $header-without-submenu;
			}
	    }
		
		body.with-submenu-small &{
			top: $header-with-submenu-small; 
			@media only screen and (max-width: $mobile-breakpoint) {
		    	top: $header-without-submenu;
		    }
	    }

	    body.with-submenu-small .main-section &{
			top: $header-with-submenu-small + $main-section-padding-top; 
			@media only screen and (max-width: $mobile-breakpoint) {
		    	top: $header-without-submenu;
		    }
	    }

		body.with-submenu-small &, body.with-submenu &{
		    @media only screen and (max-width: $mobile-breakpoint) {
		    	top: $header-without-submenu;
		    }
		}

	    width: 100%;
	    //height: 100%;

	}

	&--top{
		z-index: 100;
		height: 100%;
	}

	&--single{
		
		//margin: 50px 0;
		padding: 50px 0;

		@media only screen and (max-width: $mobile-breakpoint) {
			//padding: 20px 0;
			padding: 40px 0;
		}
		.slider{
			.slick-list{
				.slick-track{
					.slick-slide{
						height: 600px;
						img{
							height: 100%;
							width: auto;

							transition: box-shadow .3s;
						}

						@media only screen and (max-width: $mobile-breakpoint) {
							height: 100%;
							max-height: 400px;
							img{
								height: auto;
								width:auto;
							}	
						}
						// img.image-centered{
						// 	&:hover{
						// 		opacity: .3;
								

						// 		& ~ img ~ .heroCaption{
						// 			opacity: 1;
						// 			visibility: visible;
						// 		}
						// 	}
						// }
					}
				}	
			}
		}

		.container{
			height: inherit;
			position: relative;
		}

		.slick-transition{
			.slick-slide{
				&.slick-current{
					img{
						box-shadow: $box-shaddow-slide;
					}
				}
			}
		}

		img{
			&.image-spacer{
				visibility: hidden;
				opacity: 0;
			}
			
			&.image-centered{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
			}
		}	

		&.slideshow-section--bottom{
			//display: none;
			.slick-slide{
				&.slick-current{
					img{
						box-shadow: none;
					}
				}
			}
		}
		
		&.slideshow-section--top{
			//display: none;
		}
	}
}