.social-fixed.top210{top: 410px!important;}
.social-home.top210{ top: 210px!important; }

//div.home_spacer { height: 45px; width: 100%; padding: 10px 0 6px 0;}
div.home_spacer { display: none; }

div.home_spacer a.down-arrow { 
    width:36px;
    height: 24px;
    background: url(/resources/images/icon-arrow-down.png) center center no-repeat; 
    background-size: contain;
    display: block;
    position: relative;
    margin: 0 auto;
     
}
//.slideshow-section .slide + div{ height: 100%; }
//.cycle-slideshow.hero { height:100%;max-width: 2500px;margin: 0 auto;}


.social-home{ position: absolute;right: 20px;     margin-top: 40px; /* top: 0; */}

.social-home li{ margin-bottom: 20px; text-align:center;}
.social-home.top210{ top: 210px; }
.social-home li a {color:#808080; font-size:22px; }
.social-home.active{ position: fixed; top: 130px; z-index: 9999999 }

//.header .sub-menu-level2.events { background: #c2f1eb; }
.subscribe-overlay{ background: rgba(0,0,0,0.4); }




#powerTip {
	position: absolute;
	display: none;
}
.coll_holder { position: relative; }

.mobileSubNav { display: none; }

@media only screen and (max-width: 1580px){
    .geelong-slogun { display: none; }
}


@media only screen and (max-width: 1275px){
    .social-fixed { display: none; }
    .social-home { display: none; }    
}

@media only screen and (max-width: $mobile-breakpoint){
    .header .menu li.active .mobileSubNav { display: block; }

    .banner h1 {
        font-family: $light_font_family;  
        font-size: 20px; 
        line-height:1.2; 
        color:#fff!important; 
        text-shadow: none; 
        margin-bottom:6%;
    }

    .hideMob { display: none; }
}




.volunteerThanks{
	margin: 20px 0;
	display: none;
}
.volunteerThanks h2{
	text-align: center;
	margin-bottom: 10px;
}
.volunteerThanks p{
	padding: 0 20%;
	text-align: center;
}

.linkShop{
    -webkit-appearance: none;
    display: inline-block;
    background: #f177ae;
    color: #fff;
    border: 2px solid #f177ae;
    width: auto;
    padding: 10px 1.4em 9px;
    font-family: inherit;
    font-size: 0.95em;
    vertical-align: middle;
    -webkit-transition: opacity 200ms,background-color 150ms,border-color 150ms;
    -ms-transition: opacity 200ms,background-color 150ms,border-color 150ms;
    -moz-transition: opacity 200ms,background-color 150ms,border-color 150ms;
    -o-transition: opacity 200ms,background-color 150ms,border-color 150ms;
    transition: opacity 200ms,background-color 150ms,border-color 150ms;
	font-family: $bold_font_family; 
	height: 38px;
    font-size: 18px;
    font-family: $bold_font_family; 
    border: none;
    padding: 10px 14px 5px;
	margin-bottom: 10px;
    display: inline-block
	
}

.linkShop:hover {
    border-color: #f6a5ca;
    background: #f6a5ca;
    color: #fff;
}
// .linkDocument:hover{
//     border-color: #000;
//     background: #000;
//     color: #fff;

// }
// .linkDocument{
//     -webkit-appearance: none;
//     display: inline-block;
//     background: #666666;
//     color:#fff;
//     width: auto;
//     padding: 10px 1.4em 9px;
//     font-family: inherit;
//     font-size: 0.95em;
//     vertical-align: middle;
//     -webkit-transition: opacity 200ms,background-color 150ms,border-color 150ms;
//     -ms-transition: opacity 200ms,background-color 150ms,border-color 150ms;
//     -moz-transition: opacity 200ms,background-color 150ms,border-color 150ms;
//     -o-transition: opacity 200ms,background-color 150ms,border-color 150ms;
//     transition: opacity 200ms,background-color 150ms,border-color 150ms;
// 	font-family: $bold_font_family; 
// 	height: 38px;
//     font-size: 18px;
//     font-family: $bold_font_family; 
//     border: none;
//     padding: 10px 14px 5px;
// 	margin-bottom: 10px;
//     display: inline-block

// }

@media only screen and (max-width: $mobile-breakpoint){
	.volunteerThanks p{
		padding: 0;
	}
}