/* ==========================================================================
   Footer
   ========================================================================== */
.footer {
    
    //background: #191919;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    p {
        color: $black;
        font-size: 12px;
        line-height: 1.2;
        // @media only screen and (max-width: $mobile-breakpoint) { 
        //     font-size: 9px;
        // }
        strong{
            font-size: inherit;
        }
    }

    h5{
        color: inherit;
        font-family: $bold_font_family; 
        font-size: 12px;
        //  @media only screen and (max-width: $mobile-breakpoint) { 
        //     font-size: 9px;
        // }
    }

    
    .footer{
        &__inner{
            background-color: $light-grey;
            //padding: 18px 0 20px;
            color: $black;
            
            max-height: 135px;

            @media only screen and (max-width: $mobile-breakpoint) { 
                max-height: none;
            }

            $logo-height : 95px;
            .row > div{
                padding: 0 15px;
                

                @media only screen and (max-width: $mobile-breakpoint) { 
                    min-height: 120px;

                    &.hide-mobile{
                        display: none;
                    }
                }
            
                &.col-logo{
                    @media only screen and (max-width: 560px) { 
                       // width: 20%;
                        max-width: 120px;
                        padding-right: 0;
                        margin-right: 5px;

                        // & + .col2{
                        //     margin-right: 10px;
                        // }
                    }

                    .logo{
                        height: 133px;
                         @media only screen and (max-width: 560px) { 
                            height: auto;
                        }
                        // @media only screen and (max-width: $mobile-breakpoint) { 
                        //     height: auto
                        // }
                        img{
                            height: 100%;
                            //height: $logo-height; 
                            width: auto;
                            display: block;
                            // @media only screen and (min-width: $mobile-breakpoint) { 
                            //     height: 100%; 
                            // }

                            &.logo-svg{
                                display: block;
                                html.ie &, html.edge &{
                                   display: none; 
                                }
                            }

                            &.logo-png{
                                display: none;
                                html.ie &, html.edge &{
                                    display: block; 
                                }
                            }
                        }
                    }
                }
            }

            .divider--mobile{
                display: none;
                // @media only screen and (max-width: $mobile-breakpoint) { 
                //     display: block;
                //     width: 100%;
                //     display: block;
                //     width: 100%;
                //     height: 1px;
                //     float: left;
                //     position: absolute;
                //     top: $logo-height;
                //     &:after{
                //         content: '';
                //         position: relative;
                //         width: 100%;
                //         display: block;
                //         height: 10px;
                //         top: 0;
                //         box-shadow: $box-shadow-external-top;
                //     }
                // }
            }

            .widget{
                //padding: 18px 0 20px; 
                //margin-bottom: 10px;
                padding: 26px 0 20px;

                min-height: $logo-height;

                & + .widget{
                    padding-bottom: 0;
                    margin-bottom: 0;  
                } 
                @media only screen and (max-width: 560px) { 
                    padding: 20px 0 15px;
                }

                // @media only screen and (max-width: $mobile-breakpoint) { 
                //     margin-bottom: 0px;  
                //     padding: 18px 0 14px;
                // }
            }

            .small-col{
                 .widget{
                    min-height: 0;
                    & + .widget{
                        padding-top: 0px;
                    }
                }
            }

            .clear-right{
                clear: right;
            }

            &--acknowledge{
                .row > div{
                    @media only screen and (max-width: $mobile-breakpoint) { 
                        min-height: 0px;
                    }
                 }
                background-color: $footer-black;
                p{
                    color: $white;
                    padding: 18px 0;
                }
            }
        }  
    }
}


.footer h5 a {
    color: $black;
}

.footer ul li {
    line-height: 1.2;
}

.footer ul li a {
    color: $black;
    font-size: 12px;
    display: block;

    &:hover{
        color: tint($black, 50%);
    }


    // @media only screen and (max-width: $mobile-breakpoint) { 
    //     font-size: 9px;
    // }
}

.footer form {
    input[type=text] {
        display: block;
        width: 100%;
        height: 18px;
        border: none;
        background: #323232;
        font-size: 12px;
        margin: 3px 0;
    }
}
.footer form input[type=submit] {
    display: inline-block;
    background: transparent;
    border: none;
    font-size: 12px;
    color: $black;
    text-decoration: underline;
    padding: 0;
}