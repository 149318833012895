// new button styling

.btn{
	// &-show-home, &-white, &-grey{
		
	// 	a{
	// 		min-width: 175px;
	// 		background: $white;
	// 		color: $link-colour;
	// 		box-shadow: $box-shaddow-btn;
	// 		font-family: $light_font_family;
	// 		padding: 12px 16px;
	// 		font-size: 17px;
	// 		line-height: 1;
	// 		display: inline-block;
	// 		text-align: center;
	// 	}
	// }	

	min-width: 175px;
	background: $white;
	color: $link-colour;
	box-shadow: $box-shaddow-btn;
	font-family: $light_font_family;
	padding: 12px 16px;
	font-size: 17px;
	line-height: 1;
	display: inline-block;
	text-align: center;
	border: 0px;
	position: relative;
	transition: all ease .2s;

	& + .btn{
		margin-left: 10px;
	}
	
	&:after{
		content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    box-shadow: $box-shaddow-btn;
	    width: 100%;
	    height: 100%;
	    opacity: 0;
	}
	
	&:hover{
		&:after{
			opacity: 1;
		}	
	}

	&-container{
		&--center{
			width: 100%;
			text-align: center;
		}

		&--margin-bottom{
			margin-bottom: 40px
		}

		&--margin-top{
			margin-top: 40px
		}
	}

	body.events &{
		margin: 20px 0;
	}

	#geelong-gallery-foundation-dev-476 + .accordion-content &{
		margin-bottom: 40px;
	}
}



.submit-cont{

    // padding-top: 45px !important;
    // padding-bottom: 45px;
	display: block;	
	width: 175px;
	//min-width: 175px;
	//padding: 12px 0px;
	margin: 0 auto;
	position: relative;
	
	.btn{
		z-index: 10;
	}

	&:after{
		content: "";
	    position: absolute;
	    top: 0;
	    left: 0;
	    box-shadow: $box-shaddow-btn;
	    width: 100%;
	    height: 100%;
	    opacity: 0;
	}
	&:hover{
		&:after{
			opacity: 1;
		}	
	}
}

.qq-upload-button{
	&:hover{
		.btn{
			&:after{
				opacity: 1;
			}	
		}
	}
}





// .btn-show-home {
//     text-align: center;
// }

// .btn-show-home a {
//     display: inline-block;
//     font-family: $bold_font_family; 
//     color: #ffffff;
//     background: #666666;
//     width: 210px;
//     padding: 14px 0px 6px;
//     text-align: center;
// }
